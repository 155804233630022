<script>
    import registrationForm from './registration-form.vue';

    export default{
        components: {
            registrationForm,

        }
    }
</script>
